import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {LaptopMac, Chat, Coffee, GitHub, LinkedIn, Email} from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import {useTheme} from "@mui/material/styles";
import {Divider, Grid} from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Typography variant="h3" component="h2" textAlign={"center"}>
                        kerri crawford
                    </Typography>
                    <Typography variant="h6" component="h2" textAlign={"center"}>
                        cats. coffee. code.
                    </Typography>
                </Box>
                <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example" centered>
                    <Tab icon={<Coffee />} label="about" />
                    <Tab icon={<LaptopMac />} label="experience" />
                    <Tab icon={<Chat />} label="contact" />
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={2}>
                            <h2 style={{textAlign: "center", marginTop: 0}}>hiiii! i'm kerri.</h2>
                            <p style={{textAlign: "center"}}>
                                i'm a software engineer primarily working on backend services. i enjoy learning new things and honing my skills in any way i can.<br />
                                i love working on small teams that allow me to wear multiple hats and make significant contributions.
                                <br/><br/>
                                currently, i'm working at <a href={"https://www.dispenseapp.com"}>Dispense</a>, a company focused on e-Commerce for medical and recreational cannabis dispensaries across the country.
                                <br/>
                                i'm also a student at the University of Texas at Austin working on completing my Master of Science in Computer Science.
                                <br/><br/>
                                when i'm not working or studying, you'll probably find me curled up with a coffee reading a book (Stephen King's my fave) joined by at least one of my five cats.
                                <br/>
                                yes, i am a crazy cat lady :)
                            </p>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={2}>
                            <h3 style={{textAlign: "center"}}>skills / languages / frameworks</h3>
                            <p>
                                <b>strong:</b> JavaScript/TypeScript, Node.js, Redis, SQL, NoSQL, version control, REST APIs, AWS, Docker
                                <br />
                                <b>experienced:</b> authentication, Python, Java, C++, pandas, numpy, React
                            </p>
                            <Divider />
                            <h3 style={{textAlign: "center"}}>work experience</h3>
                            <h4 style={{marginBottom: 0}}>senior software engineer @ Dispense</h4>
                            <p style={{marginTop: 0, marginBottom: 0}}>
                                nov 2022 - current
                            </p>
                            <ul style={{marginTop: 0}}>
                                <li>developed and implemented an automated product catalog system, matching retailer products and populating missing information, such as descriptions and images; currently managing a catalog of 120,000 products with adoption by 60 stores.</li>
                                <li>applied the kmode clustering algorithm to train a machine learning model on a dataset of 400,000 products,
                                enabling the generation of targeted product recommendations according to defined criteria such as product type and price.</li>
                                <li>optimized the product search functionality by implementing MongoDB Atlas Search custom indexes,
                                resulting in a 90% improvement in search time and enhancing overall system performance.</li>
                            </ul>
                            <h4 style={{marginBottom: 0}}>software engineer @ Blended Edge</h4>
                            <p style={{marginTop: 0, marginBottom: 0}}>
                                july 2021 - nov 2022
                            </p>
                            <ul style={{marginTop: 0}}>
                                <li>created a VSCode extension utilizing TypeScript and NodeJS, which applies JSONata queries to JSON
                                objects, expediting the development of Doohickey integrations, resulting in a 50% reduction in man-hours
                                required for the integration process.</li>
                                <li>Played a key role in bringing Doohickey's Minimum Viable Product (MVP) to fruition, a CLI tool designed
                                for simplified integration through templated integrations. Leveraged the Open Integration Hub, an open-source framework, and actively contributed from project initiation through the successful onboarding of the first production customer.</li>
                            </ul>
                            <h4 style={{marginBottom: 0}}>senior processing specialist @ Assurant</h4>
                            <p style={{marginTop: 0, marginBottom: 0}}>
                                july 2016 - march 2021
                            </p>
                            <ul style={{marginTop: 0}}>
                                <li>spearheaded the analysis of a loan portfolio comprising 20,000 loans lacking homeowners insurance data,
                                successfully identifying insurance carriers for 85% of the portfolio. Implemented Excel custom macros and leveraged proprietary software to automate updates, significantly minimizing the risk of lender-placed insurance.</li>

                            </ul>
                            <Divider/>
                            <h3 style={{textAlign: "center"}}>education</h3>
                            <h4 style={{marginBottom: 0}}>Master of Science, Computer Science @ University of Texas at Austin</h4>
                            <p style={{marginTop: 0}}>Expected Graduation 2026</p>
                            <h4 style={{marginBottom: 0}}>Bachelor of Science, Computer Science @ Western Governors University (WGU)</h4>
                            <p style={{marginTop: 0}}>Graduation 2023</p>
                            <h4 style={{marginBottom: 0}}>Software Engineering Immersive @ Codesmith</h4>
                            <p style={{marginTop: 0}}>12-week program from March 2021 to June 2021</p>
                            <h4 style={{marginBottom: 0}}>Bachelor of Science, Business Management @ WGU</h4>
                            <p style={{marginTop: 0}}>Graduation 2020</p>
                            <Divider/>
                            <h3 style={{textAlign: "center"}}>certifications</h3>
                            <h4 style={{marginTop: 0, marginBottom: 0}}>ITIL 4</h4>
                            <h4 style={{marginTop: 0, marginBottom: 0}}>Google Associate Cloud Engineer</h4>
                            <h4 style={{marginTop: 0, marginBottom: 0}}>AWS Certified Cloud Practitioner</h4>
                            <h4 style={{marginTop: 0, marginBottom: 0}}>AWS Certified Developer Associate</h4>
                            <h4 style={{marginTop: 0, marginBottom: 0}}>AWS Certified Solutions Architect Associate</h4>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={2}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <GitHub/>
                                <p style={{paddingLeft: 10}}><b>GitHub:</b> <a href={"https://github.com/kerriannercrawford"}>kerriannercrawford</a></p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <LinkedIn />
                                <p style={{paddingLeft: 10}}><b>LinkedIn:</b> <a href={"https://www.linkedin.com/in/kerriannercrawford/"}>kerriannercrawford</a></p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <Email />
                                <p style={{paddingLeft: 10}}><b>Email:</b> <a href={"mailto: kerri@kerricrawford.dev"}>kerri@kerricrawford.dev</a></p>
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
            </SwipeableViews>
        </Box>
    );
}